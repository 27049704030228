import React from 'react';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Table, Tag, Space, PageHeader, Button, notification } from 'antd';


export default function App() {
  const [vpn_data, setVPNData] = useState([]);
  const rvpn_backend_url = "https://rvpn.sea.com"

  useEffect(() => {
    if (typeof window != 'undefined') {
      window.onpageshow = event => {
        if (event.persisted) {
          window.location.reload()
        }
      }
    }
  }, [])

  useEffect(() => {
    document.title = "RVPN"
    if (!localStorage.getItem('rvpnUser') || !localStorage.getItem('rvpnToken')) {
      const url = new URL(window.location.href);
      const authCode = url.searchParams.get('code')
      if (authCode) {
        fetch(`${rvpn_backend_url}/clientToken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ "code": authCode })
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.isSuc) {
              console.log(data)
              const clientToken = data.client_token
              const clientUser = data.user
              localStorage.setItem('rvpnUser', clientUser)
              localStorage.setItem('rvpnToken', clientToken)
              update_data()
            } else {
              window.location.assign("https://auth.garenanow.com/app/clients/d5a7acf550569f68/login/?redirect_uri=https://rvpn.sea.com")
            }
          }).catch((e) => {
            window.location = 'https://auth.garenanow.com/app/clients/d5a7acf550569f68/login/?redirect_uri=https://rvpn.sea.com'
          }
          )
      } else {
        console.log('no auth code')
        window.location = 'https://auth.garenanow.com/app/clients/d5a7acf550569f68/login/?redirect_uri=https://rvpn.sea.com'
      }
    } else {
      const user = localStorage.getItem('rvpnUser')
      const token = localStorage.getItem('rvpnToken')
      if (user === null || token === null) {
        window.location = 'https://auth.garenanow.com/app/clients/d5a7acf550569f68/login/?redirect_uri=https://rvpn.sea.com'
      }
    }
  }, []);

  useEffect(() => {
    update_data()
    const interval = setInterval(() => {
      update_data(counter => counter + 1);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const restart_vpn = (server) => {
    const token = localStorage.getItem('rvpnToken')
    fetch(`${rvpn_backend_url}/restart?server=${server}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data)
        setVPNData(data.data);
        vpn_submit_suc()
      })
      .catch((e) => {
        vpn_submit_err();
        console.log(e);
      });
  }

  const vpn_submit_suc = () => {
    notification['success']({
      message: 'Submit Success',
      description:
        'The restart cmd has been submitted,status will be refreshed every 3 secs',
    });
  };

  const vpn_submit_err = () => {
    notification['error']({
      message: 'Submit Failed',
      description:
        'The restart cmd submitted failed,Please contact zhangqc@seagroup.com',
    });
  };

  const columns = [
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      width: 150,
      render: text => <p>{text}</p>,
    },
    {
      title: 'Servers',
      dataIndex: 'server',
      key: 'server',
      width: 200,
    },
    {
      title: 'Status',
      key: 'vpn_status',
      dataIndex: 'vpn_status',
      width: 100,
      render: status => {
        let color = 'green';
        if (status === 'down' || status === 'restart_failed' || status === 'alarm_failed') {
          color = 'volcano'
        }
        if (status === 'restarting' || status === 'alarm_sent' || status === 'alarm_sending') {
          color = 'geekblue'
        }
        return <Tag color={color} key={status}>{status.toUpperCase()}</Tag>
      },
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 100,
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => restart_vpn(record.server)}>Restart</Button>
          {/* <Button type="primary" onClick={() => send_network_alarm(record.target)}>Send Network Alarm</Button> */}
        </Space>
      ),
    },
  ];

  const update_data = () => {
    const token = localStorage.getItem('rvpnToken')
    fetch(`${rvpn_backend_url}/get_all_vpn_items`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.isSuc) {
          setVPNData(data.data);
        }
      })
      .catch((e) => { console.log(e); });
  }

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title="VPN management"
        footer="Status will be refreshed every 3 sec"
      />
      <Table columns={columns} dataSource={vpn_data} pagination={false} />
    </div>
  );
}